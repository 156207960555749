import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import useLanguage from '../../hooks/useLanguage';
import { useEffect } from "react";
import moment from "moment";

function RechargeDetails({ data, loading, handleExcutePayment, setPaymentMethod, paymentMethod }) {
  const { t } = useTranslation();
  const [language] = useLanguage();

  useEffect(() => {
    console.log(language);
  }, [language]);

    // Helper function to format the date
    const formatDate = (dateString) => {
        return moment(dateString).format('MMMM D, YYYY, h:mm A');
      };
  return (
    <div dir={language === "a" ? "rtl" : "ltr"}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="font-weight-bold">{t("rechargeDetails")}</h3>
      </div>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-muted">{t("rechargeId")}</h6>
          <h6 className="font-weight-bold">{data.payment_transaction.id}</h6>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-muted">{t("rechargeDate")}</h6>
          <h6 className="font-weight-bold">{formatDate(data.payment_transaction.payment_created_at)}</h6>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-muted">{t("rechargeAmount")}</h6>
          <h6 className="font-weight-bold">{data.payment_transaction.invoice_amount}</h6>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-muted">{t("rechargeTotalAmount")}</h6>
          <h6 className="font-weight-bold">{paymentMethod?.TotalAmount}</h6>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-muted">{t("rechargeStatus")}</h6>
          <h6 className="font-weight-bold">{data.payment_transaction.payment_status}</h6>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-muted">{t("rechargeMethod")}</h6>
          <Select
  className="w-50"
  placeholder={t("selectMethod")}
  options={data.PaymentMethods.map((method) => ({
    label: method.PaymentMethodEn,
    value: method.PaymentMethodId,
  }))}
  onChange={(value) =>
    setPaymentMethod(
      data.PaymentMethods.find((method) => method.PaymentMethodId === value)
    )
  }
  getPopupContainer={(triggerNode) => triggerNode.parentNode}
  dropdownStyle={{ zIndex: 1050 }}
/>

        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <button
            className="btn btn-primary"
            onClick={handleExcutePayment}
            disabled={loading || !paymentMethod}
          >
            {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} /> : t("excutePayment")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RechargeDetails;