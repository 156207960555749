import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Image, Button, Spinner, Alert, Form } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import ErrorHandler from './error-handler/ErrorHandler';
import axiosInstance from '../api/axios';
import { logOut } from '../global-state/actions/authActions';
import { useHistory, useParams } from 'react-router-dom'; // Import useParams
import SideNavBar from './sideNavBar';
import ClientsTable from './clientsTable';
import ProductsTable from './productsTable';
import { useTranslation } from 'react-i18next';
import '../style-sheets/profile.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MerchantPackageBadge from './MerchantPackageBadge'; // Import the badge component
import { notification } from 'antd';


function ProfilePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [seller, setSeller] = useState(null);
  const [merchantPackage, setMerchantPackage] = useState(null); // State for merchant package
  const [backgroundColor, setBackgroundColor] = useState('#1abc9c');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  const [profilePic, setProfilePic] = useState(null);
  const [coverPic, setCoverPic] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [name, setName] = useState('');
  const [activeShipmentProvider, setActiveShipmentProvider] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#1abc9c');
  const [uploading, setUploading] = useState(false);
  const { success } = useParams(); // Extract the success parameter

  useEffect(() => {
    if (success) {
      toast.success(t('paymentSuccess'), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  }, [success, t]);


  const fetchSeller = async () => {
    try {
      const response = await axiosInstance.get('/profile/');
      const { user, seller: sellerData, merchant_package } = response.data;
      setSeller(response.data);
      setMerchantPackage(merchant_package); // Set merchant package
      setStoreName(sellerData.store_name || '');
      setName(user.name || '');
      setActiveShipmentProvider(sellerData.active_shipment_provider || false);
      setSelectedColor(sellerData.profile_color || '#1abc9c');
      setBackgroundColor(sellerData.profile_color || '#1abc9c');
      setProfilePic(user.image ? `${axiosInstance.defaults.baseURL}${user.image}` : null);
      setCoverPic(user.profile_cover ? `${axiosInstance.defaults.baseURL}${user.profile_cover}` : null);
      setError(null);
    } catch (error) {
      console.error('Error fetching seller data:', error);
      setError(t('error.fetchSeller'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSeller();
  }, []);

  useEffect(() => {
    if (merchantPackage && merchantPackage.remaining_orders === 0) {
      notification.warning({
        message: t('renewmsg'),
        duration: 0, // Keep the notification open until user dismisses it
        btn: (
          <Button 
            type="primary" 
            onClick={() => {
              history.push('/packages');
              notification.destroy(); // Close the notification after clicking the button
            }}
            style={{
              backgroundColor: '#007bff',
              borderColor: '#007bff',
              borderRadius: '5px',
            }}
          >
            {t('renew')}
          </Button>
        ),
        placement: 'topRight', // Position of the notification
        style: {
          width: 300, // Customize the width
          borderRadius: '10px', // Rounded corners
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)', // Soft shadow
        },
      });
    }
  }, [merchantPackage, t, history]);

  const handleRenewClick = () => {
    history.push('/packages');
  };


  const handleLogout = () => {
    dispatch(logOut());
    history.push('/login');
  };

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  const handleFileChange = (e, setter) => {
    setter(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (profilePic instanceof File) formData.append('image', profilePic);
    if (coverPic instanceof File) formData.append('profile_cover', coverPic);
    formData.append('store_name', storeName);
    formData.append('name', name);
    formData.append('active_shipment_provider', activeShipmentProvider);
    formData.append('profile_color', selectedColor);
  
    setUploading(true);
    try {
      const response = await axiosInstance.put('/profile/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSeller(response.data);
      setError(null);
      toast.success(t('profile.updateSuccess'));
      await fetchSeller();
    } catch (error) {
      console.error('Error updating seller data:', error);
      setError(t('error.updateSeller'));
      toast.error(t('profile.updateFail'));
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return (
      <div className="loading-spinner" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="mt-3">
        {error}
      </Alert>
    );
  }

  return (
    <div className="profile-wrapper" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <Container fluid className="profile-container">
     <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={i18n.language === 'ar'} pauseOnFocusLoss draggable pauseOnHover />
        <Row className="cover-picture" style={{ maxHeight: '300px', overflow: 'hidden' }}>
          {coverPic && <Image src={coverPic} fluid alt={t('profile.coverPicture')} className="object-fit-cover" />}
        </Row>
        
        <Row>
          <Col xs={12} md={2}>
          
            <SideNavBar onSelect={handleSelect} onLogout={handleLogout} store_name={storeName} merchantPackage={merchantPackage}/>
            
          </Col>
          <Col xs={12} md={10}>
            {activeTab === 'profile' && (
              <>
                <Row className="profile-picture-row justify-content-center">
                  <Col xs={6} md={4} className="d-flex justify-content-center">
                    <div className="profile-picture-container">
                      {profilePic && <Image src={profilePic} roundedCircle fluid alt={t('profile.profilePicture')} className="profile-picture" />}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5 justify-content-center align-items-center text-center">
                  <Col xs={10} md={6}>
                    <p className="welcome-text" style={{ fontStyle: 'bold' , fontSize: '20px' }}>{t('profile.welcome')}: {storeName}</p>
                  </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                  <Col xs={10} md={6}>
                    <Form>
                      <Form.Group controlId="formStoreName" className="mb-3">
                        <Form.Label style={{ fontStyle: 'bold' , fontSize: '20px' }}>{t('profile.storeName')}</Form.Label>
                        <Form.Control type="text" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                      </Form.Group>
                      <hr></hr>
                      <Form.Group controlId="formName" className="mb-3">
                        <Form.Label style={{ fontStyle: 'bold' , fontSize: '20px' }}>{t('profile.name')}</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                      </Form.Group>
                      <hr></hr>
                      <Form.Group controlId="formActiveShipmentProvider" className="mb-3">
                        <Form.Check type="checkbox"  style={{ fontStyle: 'bold' , fontSize: '20px' }} label={t('profile.activeShipmentProvider')} checked={activeShipmentProvider} onChange={(e) => setActiveShipmentProvider(e.target.checked)} />
                      </Form.Group>
                      <hr></hr>
                      <Form.Group controlId="formProfilePic" className="mb-3">
                        <Form.Label style={{ fontStyle: 'bold' , fontSize: '20px' }}>{t('profile.uploadProfilePicture')}</Form.Label>
                        <Form.Control type="file" onChange={(e) => handleFileChange(e, setProfilePic)} />
                      </Form.Group>
                       <hr></hr>
                      <Form.Group controlId="formCoverPic" className="mb-3">
                        <Form.Label style={{ fontStyle: 'bold' , fontSize: '20px' }}>{t('profile.uploadCoverPicture')}</Form.Label>
                        <Form.Control type="file" onChange={(e) => handleFileChange(e, setCoverPic)} />
                      </Form.Group>
                      <hr></hr>
                      <Form.Group controlId="formProfileColor" className="mb-3">
                        <Form.Label style={{ fontStyle: 'bold' , fontSize: '20px' }}>{t('profile.profileColor')}</Form.Label>
                        <ChromePicker color={selectedColor} onChange={(color) => setSelectedColor(color.hex)} />
                      </Form.Group>
                      
                      <Button variant="primary" onClick={handleUpload} disabled={uploading} style={{ width: '100%' }}>
                        {uploading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('profile.updateProfile')}
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </>
            )}
            {activeTab === 'clients' && (
              <Row className="mt-3 justify-content-center align-items-center">
                <Col xs={12}>
                  <ClientsTable merchantPackage={merchantPackage} />
                </Col>
              </Row>
            )}
            {activeTab === 'products' && (
              <Row className="mt-3 justify-content-center align-items-center">
                <Col xs={12}>
                  <ProductsTable />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ErrorHandler(ProfilePage, axiosInstance);
