import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function useLanguage() {
    const setLocalStorage = (newLang) => {
        localStorage.setItem("lang", newLang);
        localStorage.setItem("i18nextLng", newLang);
    };
    const { i18n } = useTranslation();
    useEffect(() => {
        if (i18n.language === "undefined")
            i18n.changeLanguage("ar").then(() => setLocalStorage("ar"));
        else if (i18n.language === "en-US")
            i18n.changeLanguage("en").then(() => setLocalStorage("en"));
        else setLocalStorage(i18n.language);
    }, [i18n, i18n.language]);
    return i18n.language ?? "ar";
}

export default useLanguage;
