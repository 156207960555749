import React, { useState, useEffect } from "react";
import { Container, Form, Button, Stack, Image } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { logOut, userLogin } from "../global-state/actions/authActions";
import { bindActionCreators } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style-sheets/login.css";
import { Link, useHistory, useParams } from "react-router-dom";
import axiosInstance from '../api/axios';
import logoSeller from "../images/direct_logo.png";

import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
import { LanguageContext } from "../contexts/LanguageContext";

const Login = (props) => {
    const params = useParams();
    const isAuth = useSelector((state) => state.auth.isAuth);
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [passErr, setPassErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const { t, i18n } = useTranslation();
    const language = useLanguage();

    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
            setEmail(e.target.value);
            setEmailErr("");
        } else {
            setEmailErr(t("email_err"));
        }
    };

    const handlePass = (e) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        console.log("Initial base URL:", axiosInstance.defaults.baseURL);
    }, []);

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let loginRequest = {
            email: email.toLowerCase(),
            password: password
        };

        const isSubscribed = await props.userLogin(loginRequest, rememberMe);

        if (isSubscribed) {
            history.replace("/profile");
        } else {
            history.replace("/packages");
        }

        setLoading(false);
    };

    const languageChecker = (englishCallback, arabicCallback) =>
        language.includes("en") ? englishCallback : arabicCallback;

    return (
        <div className="loginContainer">
            <LanguageContext.Provider value={{ language }}>
                <Container className="pageContainer">
                    <Stack gap={2} className="col-md-8 mx-auto">
                        <div className="login-header" dir={languageChecker("ltr", "rtl")}>
                            <div className="logo">
                                <Image src={logoSeller} alt="logo" />
                            </div>
                            <button
                                onClick={() => {
                                    const newLang = languageChecker("ar", "en");
                                    i18n.changeLanguage(newLang);
                                }}
                            >
                                {languageChecker("ع", "EN")}
                            </button>
                        </div>
                        <Form className="login-form mt-4" onSubmit={submit}>
                            <Form.Group controlId="email" className="form-group">
                                <Form.Control
                                    type="email"
                                    placeholder={t("email")}
                                    value={email}
                                    onChange={handleEmail}
                                    className="form-control"
                                />
                                <p className="error">{emailErr}</p>
                            </Form.Group>

                            <Form.Group controlId="password" className="form-group">
                                <Form.Control
                                    type="password"
                                    placeholder={t("password")}
                                    value={password}
                                    onChange={handlePass}
                                    className="form-control"
                                />
                                <p className="error">{passErr}</p>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={loading} className="btn mt-3">
                                {loading ? "Loading..." :  t("login") }
                            </Button>
                        </Form>

                        <p style={{ textAlign: "end" }}>
                            { t("no_account") }{" "}
                            <Link to={"/register"} style={{ color: "#a8c73b" }}>{"  "}{ t("register") }{"   "}</Link>
                        </p>

                        <ToastContainer position="bottom-right" />
                    </Stack>
                </Container>
            </LanguageContext.Provider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ userLogin, logOut }, dispatch);
};

export default connect(null, mapDispatchToProps)(Login);
