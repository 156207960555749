import React from 'react';
import { useLocation } from 'react-router-dom';
import OrderForm from './OrderForm';

const Order = () => {
    const location = useLocation();
    const { client } = location.state || {};

    return (
        <div>
            <h1>Order Page</h1>
            
            {client ? (
                <OrderForm clientData={client} />
            ) : (
                <p>No client data available.</p>
            )}
        </div>
    );
};

export default Order;