import React, { useState } from 'react';
import { Card, Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../api/axios';
import RechargeDetails from './RechargeDetails';
import { notification } from 'antd';
import useLanguage from '../../hooks/useLanguage';

const PackageCard = ({ id, name, name_en, description, description_en, price, language }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [methodLoading, setMethodLoading] = useState(false);
  const [excutionLoading, setExcutionLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [languageHook] = useLanguage();

  const fetchPaymentMethods = async () => {
    setMethodLoading(true);
    try {
      const result = await axiosInstance.post('/initiate-payment/', { package_id: id });
      setPaymentData(result.data);
      setShowModal(true);
    } catch (err) {
      handleErrors(err);
    } finally {
      setMethodLoading(false);
    }
  };

  const handleErrors = (err) => {
    if (err.response?.data) {
      Object.entries(err.response.data).forEach(([key, value]) => {
        notification.error({
          message: key,
          description: value,
          placement: 'bottomRight',
        });
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Something went wrong. Please try again later.',
        placement: 'bottomRight',
      });
    }
  };

  const handleExcutePayment = async () => {
    setExcutionLoading(true);
    const data = {
      payment_method_id: paymentMethod.PaymentMethodId,
      payment_method_name: paymentMethod.PaymentMethodEn,
      total_amount: paymentMethod.TotalAmount,
    };
    try {
      const result = await axiosInstance.post(
        `/execute-payment/${paymentData.payment_transaction.id}/`,
        data
      );
      if (result?.status === 200) {
        notification.success({
          message: "Success",
          description: "You will be redirected to the payment page in 2 seconds",
          placement: "bottomRight",
        });
        setTimeout(() => {
          window.location.replace(result.data.payment_url);
        }, 2000);
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      setExcutionLoading(false);
    }
  };

  const handleSubscribe = async () => {
    await fetchPaymentMethods();
  };

  const getName = () => {
    return language.includes('en') ? name_en : name;
  };

  const getDescription = () => {
    const desc = language.includes('en') ? description_en : description;
    return desc.split('\r\n').map((line, index) => (
      <p key={index} style={{ margin: '0' }}>
        {line}
      </p>
    ));
  };

  return (
    <>
      <Card style={{ boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', borderRadius: '10px', transition: 'box-shadow 0.3s ease-in-out', width: '18rem', margin: '1rem auto', textAlign: 'center', fontWeight: 'bold' }}>
        <Card.Body>
          <Card.Title  style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{getName()}</Card.Title>
          <Card.Text>{getDescription()}</Card.Text>
          <Card.Text  style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{t('Packageprice')}</Card.Text>
          <Card.Text>{price}</Card.Text>
          <Button variant="primary" onClick={handleSubscribe} style={{ backgroundColor: 'rgb(92, 85, 165)', fontWeight: 'bold' }}>
            {t('subscribeButton')}
          </Button>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('paymentDetails')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {methodLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          ) : paymentData ? (
            <RechargeDetails
              data={paymentData}
              loading={excutionLoading}
              handleExcutePayment={handleExcutePayment}
              setPaymentMethod={setPaymentMethod}
              paymentMethod={paymentMethod}
            />
          ) : (
            <div className="text-center">
              <p>{t('noPaymentData')}</p>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                {t('closeButton')}
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PackageCard;
