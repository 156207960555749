import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const ClientsColumns = (makeOrder, shipmentProviders, handleProviderChange , hide) => {
    const { t } = useTranslation();
    return [
        {
            title: t('clientsTable.id'),
            width: 100,
            dataIndex: "id"
        },
        {
            title: t('clientsTable.clientName'),
            width: 150,
            dataIndex: "Client_Name"
        },
        {
            title: t('clientsTable.phone'),
            width: 150,
            dataIndex: "Client_Phone",
            render: (text, record) => !record.status ? null : text
        },
        {
            title: t('clientsTable.altPhone'),
            width: 150,
            dataIndex: "Client_Phone_alt",
            render: (text, record) => !record.status ? null : text
        },
        {
            title: t('clientsTable.area'),
            width: 150,
            dataIndex: "Client_Area_name",
            render: (text, record) => !record.status ? null : text
        },
        {
            title: t('clientsTable.address'),
            width: 200,
            dataIndex: "Client_Address",
            render: (text, record) => !record.status ? null : text
        },
        {
            title: t('clientsTable.status'),
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (status) => {
                return status ? (
                    <Tag color="green">{t("clientsTable.accepted")}</Tag>
                ) : (
                    <Tag color="red">{t("clientsTable.pending")}</Tag>
                );
            }
        },
        {
            title: t('clientsTable.shipmentProvider'),
            width: 200,
            render: (text, record) => {
                const selectedProvider = record.selectedShipmentProvider || record.shipment_provider || '';
                return (
                    <Form.Control
                        as="select"
                        value={selectedProvider}
                        onChange={(e) => handleProviderChange(record.id, e.target.value)}
                    >
                        <option value="">{t("clientsTable.shipmentProvider")}</option>
                        {shipmentProviders.map(provider => (
                            <option key={provider.shipment_provider_id} value={provider.shipment_provider_id}>
                                {provider.shipment_provider_name} - {provider.price}
                            </option>
                        ))}
                    </Form.Control>
                );
            }
        },
        {
            title: t('clientsTable.order'),
            width: 100,
            render: (text, record) => (
                !hide && (
                <Button 
                    onClick={() => makeOrder(record)} 
                    variant="primary" 
                    disabled={record.status || (!record.selectedShipmentProvider && !record.shipment_provider)}
                >
                    {t('clientsTable.order')}
                </Button> )
            ),
            headerAlign: 'center',
            align: 'center'
        }

    ];
}


export const ProductColumns = (editProduct, deleteProduct) => {
    const { t } = useTranslation();
    return [
        {
            title: t('productsTable.id'),
            width: 100,
            dataIndex: "id"
        },
        {
            title: t('productsTable.createDate'),
            width: 150,
            dataIndex: "create_date",
            render: (text) => moment(text).format('DD/MM/YY')
        },
        {
            title: t('productsTable.name'),
            width: 150,
            dataIndex: "name"
        },
        {
            title: t('productsTable.price'),
            width: 100,
            dataIndex: "price"
        },
        {
            title: t('productsTable.description'),
            width: 200,
            dataIndex: "description"
        },
       
    ];
}
