import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Form, Spinner, Alert, Toast } from 'react-bootstrap';
import ErrorHandler from './error-handler/ErrorHandler';
import axiosInstance from '../api/axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import '../style-sheets/profile.css';
import TablePro from './TablePro/TablePro';
import { ProductColumns } from './tableColumns/Column'; // Updated import

function ProductsTable() {
  const { t } = useTranslation(); // Initialize useTranslation hook

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newProduct, setNewProduct] = useState({ name: '', price: '', description: '' });
  const [toast, setToast] = useState({ show: false, message: '', variant: '' });

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get('products/');
      setProducts(response.data.data);
    } catch (error) {
      console.error('Error fetching products data:', error);
      setError(t('error.fetchProducts'));
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchProducts();
  }, [t]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('products/create/', newProduct);
      setProducts((prevState) => [...prevState, response.data]);
      setToast({ show: true, message: t('productsTable.createSuccess'), variant: 'success' });
      fetchProducts();
      handleCloseModal();
    } catch (error) {
      console.error('Error creating product:', error);
      setToast({ show: true, message: t('productsTable.createFail'), variant: 'danger' });
    }
  };

  const columns = ProductColumns();

  if (loading) {
    return (
      <div className="loading-spinner">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="mt-3">
        {error}
      </Alert>
    );
  }

  return (
    <div className="profile-wrapper" style={{ minHeight: '100vh' }}>
      <Container fluid className="profile-container">
        <Row className="mt-5 justify-content-center align-items-center">
          <Col xs={10}>
            <Button variant="primary" onClick={handleShowModal} className="mb-3">
              {t('productsTable.addProduct')}
            </Button>
            <TablePro dataSource={products} columns={columns} />
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('productsTable.addProduct')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formProductName">
              <Form.Label>{t('productsTable.name')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newProduct.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formProductPrice">
              <Form.Label>{t('productsTable.price')}</Form.Label>
              <Form.Control
                type="text"
                name="price"
                value={newProduct.price}
                onChange={handleInputChange}
                pattern="^-?\d*(?:\.\d+)?$"
                required
              />
            </Form.Group>
            <Form.Group controlId="formProductDescription">
              <Form.Label>{t('productsTable.description')}</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={newProduct.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {t('productsTable.submit')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Toast
        onClose={() => setToast({ ...toast, show: false })}
        show={toast.show}
        delay={3000}
        autohide
        className={`position-fixed top-0 end-0 m-3 bg-${toast.variant}`}
      >
        <Toast.Body>{toast.message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ErrorHandler(ProductsTable, axiosInstance);
