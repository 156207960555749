import React from 'react';
import { useEffect, useState } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import Dashboard from './components/Dashboard';
import ProfilePage from './components/ProfilePage';
import PackageList from './components/Packages/PackageList';
import ClientPage from './components/ClientPage';
import Order from './components/Order';
import useLanguage from "./hooks/useLanguage"
import { LanguageContext } from "./contexts/LanguageContext";
import moment from "moment";

function App() {
  const lang = useLanguage();
  const [refresh, setRefresh] = useState(0);
  const languageChecker = (englishCallback, arabicCallback) =>
    lang.includes("en") ? englishCallback : arabicCallback;
  const direction = languageChecker("ltr", "rtl");
  const antLocale = languageChecker(
    moment.locale("en_US"),
    moment.locale("ar_EG")
  );
  console.log({
    direction,
    lang: localStorage.getItem("lang"),
    // langNext: localStorage.getItem("i18nextLng"),
    langState: lang,
  });

  return (
    <LanguageContext.Provider
      value={{
        language: lang,
        refresh,
        refreshCredit: () => {
          setRefresh((prev) => prev + 1);
        },
      }}
    >
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" exact component={ClientPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/profile/:success?" component={ProfilePage} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/packages/:failure?" component={PackageList} />
            <Route path="/order" component={Order} />
          </Switch>
        </Router>
      </Provider>
    </LanguageContext.Provider>
  );
}

export default App;
