import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation
import { Nav, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import '../style-sheets/sidenavbar.css';
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../contexts/LanguageContext";
import MerchantPackageBadge from './MerchantPackageBadge'; // Import the badge component

function SideNavBar({ onSelect, onLogout, store_name, merchantPackage }) { // Add merchantPackage as a prop
  const { t, i18n } = useTranslation();
  const { language } = useContext(LanguageContext);
  const history = useHistory(); // Initialize useHistory

  const languageChecker = (englishCallback, arabicCallback) =>
    language.includes("en") ? englishCallback : arabicCallback;

  const handleLanguageChange = () => {
    const newLang = languageChecker("ar", "en");
    i18n.changeLanguage(newLang);
  };

  const handleSelect = (selectedKey) => {
    if (onSelect) {
      onSelect(selectedKey);
    }

    if (selectedKey === 'packages') {
      history.push('/packages'); // Navigate to /packages when the eventKey is 'packages'
    }
  };


  return (
    <div className="sidenav">
      <div className="mt-2 mb-2 justify-content-center align-items-center text-center text-c">
        {/* Display Merchant Package Badge if merchantPackage exists */}
        {merchantPackage && (
          <MerchantPackageBadge 
            packageName={merchantPackage.package_name} 
            packageNameEn={merchantPackage.package_name_en} 
            remainingOrders={merchantPackage.remaining_orders} 
            subscriptionDate={merchantPackage.last_renewed_date || merchantPackage.subscription_date} 
          />
        )}
        <></>
      </div>
      <Nav className="flex-column" onSelect={handleSelect}>
        <Nav.Link eventKey="profile">{t('sideNav.profile')}</Nav.Link>
        <Nav.Link eventKey="clients">{t('sideNav.clients')}</Nav.Link>
        <Nav.Link eventKey="products">{t('sideNav.products')}</Nav.Link>
        <Nav.Link eventKey="packages">{t('sideNav.packages')}</Nav.Link> {/* This will now navigate to /packages */}
        <div className="logout-button-container mt-auto">
          <Button variant="danger" onClick={onLogout}>{t('sideNav.logout')}</Button>
        </div>
        <div className="language-toggle-container mt-3 text-center">
          <ToggleButtonGroup type="radio" name="language" value={language} onChange={handleLanguageChange}>
            <ToggleButton id="tbg-radio-1" value="en">
              EN
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value="ar">
              ع
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Nav>
    </div>
  );
}

export default SideNavBar;
