import React, { useState } from "react";
import { Container, Form, Button, Stack, Image } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style-sheets/register.css";
import { useHistory } from "react-router-dom";
import axiosInstance from '../api/axios';
import logoSeller from "../images/direct_logo.png";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";

const RegisterPage = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [formData, setFormData] = useState({
        companyName: "",
        storeName: "",
        email: "",
        phone: "",
        name: "",
        password: "",
        domainName: "",
        apiKey: "",
        serviceType: "",
        taxRegistrationNumber: "",
        taxAddress: "",
    });
    const [emailErr, setEmailErr] = useState("");
    const [taxNumberErr, setTaxNumberErr] = useState("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === "email") {
            if (value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
                setEmailErr("");
            } else {
                setEmailErr(t("Invalid email address"));
            }
        }

        if (name === "taxRegistrationNumber") {
            if (value === "" || value.match(/^33\d{13}$/)) {
                setTaxNumberErr("");
            } else {
                setTaxNumberErr(t("taxNumberErr"));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const { companyName, storeName, email, phone, name, password, taxAddress, domainName, apiKey, serviceType, taxRegistrationNumber } = formData;

        const data = {
            company_name: companyName,
            store_name: storeName,
            email,
            phone,
            name,
            password,
            domain_name: domainName,
            api_key: apiKey,
            service_type: serviceType,
            tax_registration_number: taxRegistrationNumber,
            tax_address: taxAddress,
        };

        try {
            const response = await axiosInstance.post('/seller_create/', data);
    
            if (response.status === 201 || response.status === 200) {
                toast.success(t("Seller created successfully!"));
    
                const newSubdomain = response.data.domain_name;
                console.log(newSubdomain);

                // Redirect to the dynamically constructed URL
                const baseUrl = process.env.REACT_APP_BASE_URL || (process.env.NODE_ENV === "production" ? "tree-code.app" : "localhost:3000");
                const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
                const redirectUrl = `${protocol}://${newSubdomain}.${baseUrl}/login`;
                window.location.href = redirectUrl;

            } else {
                if (response.data.error === "Invalid API key or service seller information") {
                    toast.error(t("Invalid API key or service seller information"));
                } else {
                    toast.error(t(response.data.message || "Failed to create seller"));
                }
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(t(error.response.data.error));
            } else {
                toast.error(t("An error occurred: ") + error.message);
            }
        }
        setLoading(false);
    };

    const isFormValid = () => {
        return (
            formData.companyName &&
            formData.storeName &&
            formData.email &&
            !emailErr &&
            formData.phone &&
            formData.name &&
            formData.password &&
            formData.domainName &&
            formData.apiKey &&
            (formData.taxRegistrationNumber === "" || !taxNumberErr) &&
            formData.taxAddress
        );
    };

    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'ar' : 'en';
        i18n.changeLanguage(newLang);
    };

    const language = useLanguage();
    const languageChecker = (englishCallback, arabicCallback) =>
        language.includes("en") ? englishCallback : arabicCallback;
    console.log({ directionLOG: languageChecker("ltr", "rtl"), language });

    return (
        <div className="loginContainer">
            <Container className="pageContainer">
                <Stack gap={2} className="col-md-8 mx-auto" dir={languageChecker("ltr", "rtl")}>
                    <div className="login-header">
                        <div className="logo">
                            <Image src={logoSeller} alt="logo" />
                        </div>
                    </div>
                    <Button variant="outline-primary" onClick={toggleLanguage} className="language-switcher">
                        {i18n.language === 'en' ? 'عربى' : 'English'}
                    </Button>
                    <Form className="login-form mt-4" onSubmit={handleSubmit}>
                        {["companyName", "storeName", "email", "phone", "name", "password", "taxAddress", "domainName", "apiKey", "taxRegistrationNumber"].map((field, idx) => (
                            <Form.Group controlId={field} key={idx}>
                                <Form.Control
                                    type={field === "password" ? "password" : "text"}
                                    placeholder={t(`Enter ${field}`)}
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleInputChange}
                                />
                                {field === "email" && <p className="error">{emailErr}</p>}
                                {field === "taxRegistrationNumber" && <p className="error">{taxNumberErr}</p>}
                            </Form.Group>
                        ))}

                        <Form.Group controlId="serviceType">
                            <Form.Control
                                type="text"
                                name="serviceType"
                                value={formData.serviceType}
                                placeholder={t("Enter service type")}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={loading || !isFormValid()} className="btn mt-3">
                            {loading ? t("Loading...") : t("Create Seller")}
                        </Button>
                    </Form>

                    <ToastContainer position="bottom-right" />
                </Stack>
            </Container>
        </div>
    );
};

export default RegisterPage;
