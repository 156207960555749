import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import axiosInstance from '../api/axios';
import '../style-sheets/OrderForm.css'; // Import custom CSS

const OrderForm = ({ clientData }) => {
    const [step, setStep] = useState(1);
    const [sellerAddress, setSellerAddress] = useState(null);
    const [clientInfo, setClientInfo] = useState(clientData);
    const [ticketInfo, setTicketInfo] = useState({ quantity: '', height: '', weight: '', special_label: '', product: '', return_address: '' });
    const [shipmentProviders, setShipmentProviders] = useState([]);
    const [selectedShipmentProvider, setSelectedShipmentProvider] = useState(null);

    useEffect(() => {
        axiosInstance.get('/account/')
            .then(response => {
                setSellerAddress(response.data);
            })
            .catch(error => {
                console.error('Error fetching seller address:', error);
            });

        axiosInstance.get('/shipment-providers/')
            .then(response => {
                setShipmentProviders(response.data);
            })
            .catch(error => {
                console.error('Error fetching shipment providers:', error);
            });
    }, []);

    const handleNextStep = () => setStep(step + 1);
    const handlePrevStep = () => setStep(step - 1);

    const handleSubmit = () => {
        console.log('client area is: ', clientInfo.Client_Area);
        const orderData = {
            address: { id: sellerAddress.area.id },
            client: {
                client_addresses: [
                    { address: clientInfo.Client_Address, state: clientInfo.Client_Area, zip_code: '', area: clientInfo.Client_Area }
                ],
                phone: clientInfo.Client_Phone,
                phone_alt: clientInfo.Client_Phone_alt,
                name: clientInfo.Client_Name,
                seller: sellerAddress.seller.id
            },
            product: {
                name: 'Product 1',
                price: '50.0',
                description: 'Product description',
                seller: sellerAddress.seller.id
            },
            shipment_provider_id: selectedShipmentProvider,
            tickets: [
                {
                    type: true,
                    quantity: ticketInfo.quantity,
                    height: ticketInfo.height,
                    weight: ticketInfo.weight,
                    special_label: ticketInfo.special_label,
                    product: ticketInfo.product,
                    return_address: sellerAddress.area.id
                }
            ]
        };

        axiosInstance.post('/create-order/', orderData)
            .then(response => {
                console.log('Order created successfully:', response.data);
            })
            .catch(error => {
                console.error('Error creating order:', error);
            });
    };

    return (
        <Container className="order-form-container">
            <Card className="order-form-card">
                <Card.Body>
                    {step === 1 && sellerAddress && (
                        <div>
                            <h2 className="order-form-title">Seller's Address</h2>
                            <p>{sellerAddress.address}</p>
                            <Button className="order-form-button" onClick={handleNextStep}>Next</Button>
                        </div>
                    )}

                    {step === 2 && (
                        <div>
                            <h2 className="order-form-title">Client Info</h2>
                            <Form>
                                <Form.Group controlId="clientName">
                                    <Form.Label>Client Name</Form.Label>
                                    <Form.Control type="text" value={clientInfo.Client_Name} readOnly />
                                </Form.Group>
                                <Form.Group controlId="clientPhone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" value={clientInfo.Client_Phone} readOnly />
                                </Form.Group>
                                <Form.Group controlId="clientPhoneAlt">
                                    <Form.Label>Alternate Phone</Form.Label>
                                    <Form.Control type="text" value={clientInfo.Client_Phone_alt} readOnly />
                                </Form.Group>
                                <Form.Group controlId="clientAddress">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={clientInfo.Client_Address} readOnly />
                                </Form.Group>
                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="secondary" onClick={handlePrevStep}>Back</Button>
                                    <Button className="order-form-button" onClick={handleNextStep}>Next</Button>
                                </div>
                            </Form>
                        </div>
                    )}

                    {step === 3 && (
                        <div>
                            <h2 className="order-form-title">Ticket Info</h2>
                            <Form>
                                <Form.Group controlId="quantity">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="number" value={ticketInfo.quantity} onChange={(e) => setTicketInfo({ ...ticketInfo, quantity: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="height">
                                    <Form.Label>Height</Form.Label>
                                    <Form.Control type="number" value={ticketInfo.height} onChange={(e) => setTicketInfo({ ...ticketInfo, height: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Label>Weight</Form.Label>
                                    <Form.Control type="number" value={ticketInfo.weight} onChange={(e) => setTicketInfo({ ...ticketInfo, weight: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="specialLabel">
                                    <Form.Label>Special Label</Form.Label>
                                    <Form.Control type="text" value={ticketInfo.special_label} onChange={(e) => setTicketInfo({ ...ticketInfo, special_label: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="product">
                                    <Form.Label>Product ID</Form.Label>
                                    <Form.Control type="number" value={ticketInfo.product} onChange={(e) => setTicketInfo({ ...ticketInfo, product: e.target.value })} />
                                </Form.Group>
                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="secondary" onClick={handlePrevStep}>Back</Button>
                                    <Button className="order-form-button" onClick={handleNextStep}>Next</Button>
                                </div>
                            </Form>
                        </div>
                    )}

                    {step === 4 && (
                        <div>
                            <h2 className="order-form-title">Shipment Provider</h2>
                            <Form>
                                <Form.Group controlId="shipmentProvider">
                                    <Form.Label>Shipment Provider</Form.Label>
                                    <Form.Control as="select" onChange={(e) => setSelectedShipmentProvider(e.target.value)}>
                                        <option value="">Select provider</option>
                                        {shipmentProviders.map(provider => (
                                            <option key={provider.shipment_provider_id} value={provider.shipment_provider_id}>
                                                {provider.shipment_provider_name} - {provider.price}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="secondary" onClick={handlePrevStep}>Back</Button>
                                    <Button className="order-form-button" onClick={handleSubmit}>Submit</Button>
                                </div>
                            </Form>
                        </div>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default OrderForm;
