import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "ar",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          "Creating order": "Creating order",
          "Please be patient, the order is being created": "Please be patient, the order is being created",
          "login": "Login",
          "no_account": "Don't have an account?",
          "register": "Register",
          email: "Enter Your Email",
          password: "Enter Your Password",
          email_err: "Invalid Email",
          sellerName: "Seller Name",
          "error.fetchSeller": "Failed to fetch seller data.",
          "profile.updateSuccess": "Profile updated successfully",
          "profile.updateFail": "Failed to update profile",
          "profile.coverPicture": "Cover Picture",
          "profile.profilePicture": "Profile Picture",
          "profile.welcome": "Welcome to ",
          "profile.storeName": "Store Name",
          "profile.taxNumber": "Tax Number",
          "profile.name": "Name",
          "profile.namePlaceholder": "Enter  name",
          "profile.phone": "Phone Number",
          "profile.phonePlaceholder": "Enter phone number",
          "profile.altPhone": "Alternate Phone",
          "profile.altPhonePlaceholder": "Enter alternate phone number",
          "profile.city": "City",
          "profile.selectCity": "Select a city",
          "profile.address": "Address",
          "profile.addressPlaceholder": "Enter address",
          "profile.houseImage": "House Image",
          "profile.shipmentProvider": "Shipment Provider",
          "profile.selectShipmentProvider": "Select a shipment provider",
          "profile.submit": "Submit",
          "profile.activeShipmentProvider": "Active Shipment Provider",
          "profile.uploadProfilePicture": "Upload Profile Picture",
          "profile.uploadCoverPicture": "Upload Cover Picture",
          "profile.profileColor": "Profile Color",
          "profile.updateProfile": "Update Profile",

          "sideNav.profile": "Profile",
          "sideNav.clients": "Orders",
          "sideNav.products": "Products",
          "sideNav.logout": "Logout",
          "sideNav.packages": "Packages",

          "clientsTable.createOrder": "Create Order",
          "clientsTable.product": "Product",
          "clientsTable.quantity": "Quantity",
          "clientsTable.height": "Height",
          "clientsTable.weight": "Weight",
          "clientsTable.specialLabel": "Special Label",
          "clientsTable.submit": "Submit",
          "clientsTable.confirmOrder": "Confirm Order",
          "clientsTable.cancel": "Cancel",
          "clientsTable.confirmOrderMessage": "Are you sure you want to make an order for {{clientName}}?",
          "clientsTable.id": "ID",
          "clientsTable.clientName": "Client Name",
          "clientsTable.phone": "Phone",
          "clientsTable.altPhone": "Alternate Phone",
          "clientsTable.area": "Area",
          "clientsTable.address": "Address",
          "clientsTable.shipmentProvider": "Shipment Provider",
          'clientsTable.status': 'Order Status',
          "clientsTable.accepted": "Accepted",
          "clientsTable.pending" : "Pending",
          "clientsTable.order": "Order",
          "productsTable.id": "ID",
          "productsTable.createDate": "Create Date",
          "productsTable.name": "Name",
          "productsTable.price": "Price",
          "productsTable.description": "Description",
          "productsTable.submit" : "Submit",
          "productsTable.addProduct" : "Add Product",
          "productsTable.createFail": "Failed to create product",
          "productsTable.createSuccess": "Product created successfully",
          "Create Seller": "Create Seller",
          "companyName": "Company Name",
          "storeName": "Store Name",
          "email": "Email",
          "phone": "Phone",
          "name": "Name",
          "password": "Password",
          "domainName": "Domain Name",
          "apiKey": "API Key",
          "serviceType": "Service Type",
          "taxRegistrationNumber": "Tax Registration Number",
          "taxAddress" : " Address",
          "Enter companyName": "Enter Company Name",
          "Enter storeName": "Enter Store Name",
          "Enter email": "Enter Email",
          "Enter phone": "Enter Phone",
          "Enter name": "Enter Name",
          "Enter password": "Enter Password",
          "Enter domainName": "Enter Domain Name",
          "Enter apiKey": "Enter API Key",
          "Enter taxRegistrationNumber": "Enter Tax Registration Number",
          "Enter taxAddress": "Enter Tax Address",
          "taxNumberErr": "Tax registration number must be 15 digits and start with '33'",
          "Box": "Box",
          "Salla": "Salla",
          "Invalid email address": "Invalid email address",
          "Loading...": "Loading...",
          "SHIPPING GATEWAY": "SHIPPING GATEWAY",
          'client.successMessage': 'Your Order has been created successfully',
          'client.errorMessage': 'Failed to create order. Try again later',
          'subscribeButton': 'Subscribe',
          'directPackages' : 'Direct Packages',
          "rechargeDetails": "Recharge Details",
          "rechargeId": "Recharge ID",
          "rechargeDate": "Recharge Date",
          "rechargeAmount": "Recharge Amount",
          "rechargeTotalAmount": "Total Amount",
          "rechargeStatus": "Status",
          "rechargeMethod": "Payment Method",
          "selectMethod": "Select Method",
          "excutePayment": "Execute Payment",
          "paymentDetails" : "Payment Details",        
          "orders": "orders",
          "order": "order",
          "remainingOrders": "{{count}} orders remaining",
          "remainingOrdersPlural": "{{count}} orders remaining",
          "subscriptionDate": "Subscription Date: {{date}}",
          paymentSuccess: "Payment Done Successful",
          paymentFailed: "Payment Failed",  
          Packageprice: "Package Price",
          renewmsg: "Renew your subscription",
          renew: "Packages",
          "otp.sentMessage": "OTP message sent",
          "otp.errorSending": "Error sending the message wait 2 minutes and try again",
          "otp.verifiedMessage": "OTP verified",
          "otp.invalidOtp": "Invalid OTP",
          "otp.verificationFailed": "OTP verification failed",
          "otp.enterOtp": "Enter OTP",
          "otp.enterOtpPlaceholder": "Enter OTP",
          "otp.cancel": "Cancel",
          "otp.verify": "Verify",
          "Seller created successfully!": "Seller created successfully!",
          "Invalid API key or service seller information": "Invalid API key or service seller information",
          "Failed to create seller": "Failed to create seller",
          "An error occurred: ": "An error occurred: ",
          "Enter service type" : "Enter service type",
          
        },
      },
      ar: {
        translation: {
          "Creating order": "انشاء الطلب",
          "Please be patient, the order is being created": "من فضلك انتظر قليلا حتى يتم انشاء الطلب",
          "login": "تسجيل الدخول",
          "no_account": "ليس لديك حساب؟",
          "register": "سجل",
          email: "ادخل البريد الالكتروني الخاص بك",
          password: "أدخل كلمة المرور الخاصة بك",
          email_err: "البريد الألكتروني غير صحيح",
          sellerName: "اسم البائع",
          "error.fetchSeller": "فشل في جلب بيانات البائع.",
          "profile.updateSuccess": "تم تحديث الملف الشخصي بنجاح",
          "profile.updateFail": "فشل في تحديث الملف الشخصي",
          "profile.coverPicture": "صورة الغلاف",
          "profile.profilePicture": "صورة الملف الشخصي",
          "profile.welcome": "مرحبا بك في متجر ",
          "profile.storeName": "اسم المتجر",
          "profile.taxNumber": "الرقم الضريبي",
          "profile.name": "الاسم",
          "profile.namePlaceholder": "أدخل الاسم ",
          "profile.phone": "الهاتف",
          "profile.phonePlaceholder": "أدخل رقم الهاتف",
          "profile.altPhone": "الهاتف البديل",
          "profile.altPhonePlaceholder": "أدخل رقم الهاتف البديل",
          "profile.city": "المدينة",
          "profile.selectCity": "اختر مدينة",
          "profile.address": "العنوان",
          "profile.addressPlaceholder": "أدخل العنوان",
          "profile.houseImage": "صورة المنزل",
          "profile.shipmentProvider": "مقدم الشحن",
          "profile.selectShipmentProvider": "اختر مقدم شحن",
          "profile.submit": "إرسال",
          "profile.activeShipmentProvider": "أختيار مقدم خدمة الشحن ",
          "profile.uploadProfilePicture": "تحميل صورة الملف الشخصي",
          "profile.uploadCoverPicture": "تحميل صورة الغلاف",
          "profile.profileColor": "لون الملف الشخصي",
          "profile.updateProfile": "تحديث الملف الشخصي",
          "sideNav.profile": "الملف الشخصي",
          "sideNav.clients": "الطلبات",
          "sideNav.products": "المنتجات",
          "sideNav.logout": "تسجيل خروج",
          "sideNav.packages": "الباقات",
          "clientsTable.createOrder": "انشاء طلب",
          "clientsTable.product": "المنتج",
          "clientsTable.quantity": "الكمية",
          "clientsTable.height": "الارتفاع",
          "clientsTable.weight": "الوزن",
          "clientsTable.specialLabel": "وسم خاص",
          "clientsTable.submit": "إرسال",
          "clientsTable.confirmOrder": "تأكيد الطلب",
          "clientsTable.cancel": "إلغاء",
          "clientsTable.confirmOrderMessage": "هل أنت متأكد أنك تريد إجراء طلب ل{{clientName}}؟",
          "clientsTable.id": "الرقم المعرف",
          "clientsTable.clientName": "اسم العميل",
          "clientsTable.phone": "الهاتف",
          "clientsTable.altPhone": "الهاتف البديل",
          "clientsTable.area": "المنطقة",
          "clientsTable.address": "العنوان",
          "clientsTable.shipmentProvider": "مقدم الشحن",
          'clientsTable.status': 'حالة الطلب',
          "clientsTable.accepted": "مقبول",
          "clientsTable.pending" : "قيد الانتظار",
          "clientsTable.order": "الطلب",
          "productsTable.id": "الرقم المعرف",
          "productsTable.createDate": "تاريخ الإنشاء",
          "productsTable.name": "الاسم",
          "productsTable.price": "السعر",
          "productsTable.description": "الوصف",
          "productsTable.submit" : "إرسال",
          "productsTable.addProduct" : "اضافة منتج",
          "productsTable.createFail": "فشل في انشاء المنتج",
          "productsTable.createSuccess": "تم انشاء المنتج بنجاح",
          "Create Seller": "إنشاء البائع",
          "companyName": "اسم الشركة",
          "storeName": "اسم المتجر",
          "email": "البريد الإلكتروني",
          "phone": "الهاتف",
          "name": "الاسم",
          "password": "كلمة المرور",
          "domainName": "اسم النطاق",
          "apiKey": "مفتاح API",
          "serviceType": "نوع الخدمة",
          "taxRegistrationNumber": "رقم التسجيل الضريبي",
          "taxAddress" : "العنوان ",
          "Enter companyName": "أدخل اسم الشركة",
          "Enter storeName": "أدخل اسم المتجر",
          "Enter email": "أدخل البريد الإلكتروني",
          "Enter phone": "أدخل الهاتف",
          "Enter name": "أدخل الاسم",
          "Enter password": "أدخل كلمة المرور",
          "Enter domainName": "أدخل اسم النطاق",
          "Enter apiKey": "أدخل مفتاح API",
          "Enter taxRegistrationNumber": "أدخل رقم التسجيل الضريبي",
          "Enter taxAddress": "أدخل العنوان الضريبي",
          "taxNumberErr": "رقم التسجيل الضريبي يجب أن يكون 15 رقم ويبدء ب'33'",
          "Box": "بوكس",
          "Salla": "سلة",
          "Invalid email address": "عنوان البريد الإلكتروني غير صالح",
          "Loading...": "جار التحميل...",
          'client.successMessage': 'تم انشاء الطلب بنجاح',
          'client.errorMessage': 'فشل في انشاء الطلب. حاول مرة أخرى',
          'subscribeButton': 'اشترك',
          'directPackages' : 'حزم دايركت',
          "rechargeDetails": "تفاصيل الشحن",
          "rechargeId": "رقم الشحن",
          "rechargeDate": "تاريخ الشحن",
          "rechargeAmount": "مبلغ الشحن",
          "rechargeTotalAmount": "المبلغ الإجمالي",
          "rechargeStatus": "الحالة",
          "rechargeMethod": "طريقة الدفع",
          "selectMethod": "اختر طريقة الدفع",
          "excutePayment": "تنفيذ الدفع",    
          "paymentDetails" : "تفاصيل الدفع",  
          "orders": "طلبات",
          "order": "طلب",
          "remainingOrders": "{{count}} طلبات متبقية",
          "remainingOrdersPlural": "{{count}} طلبات متبقية",
          "subscriptionDate": "تاريخ الاشتراك: {{date}}",
          paymentSuccess: "تمت عملية الدفع بنجاح",
          paymentFailed: "فشل في عملية الدفع",
          Packageprice: "سعر الباقة",
          renewmsg: "تجديد الاشتراك",
          renew: "الباقات",
          "otp.sentMessage" : "تم ارسال رسالة OTP",
          "otp.errorSending ": "خطأ في ارسال الرسالة",
          "otp.verifiedMessage" : "تم التحقق من OTP",
          "otp.invalidOtp" : "خطأ في التحقق من OTP",
          "otp.verificationFailed" : "خطأ في التحقق من OTP",
          'otp.enterOtp' : "ادخل OTP",
          'otp.enterOtpPlaceholder' : "ادخل OTP",
          'otp.cancel' : "الغاء",
          'otp.verify' : "تحقق",
          "Seller created successfully!": "تم إنشاء البائع بنجاح!",
          "Invalid API key or service seller information": "مفتاح API غير صالح أو معلومات البائع",
          "Failed to create seller": "فشل في إنشاء البائع",
          "An error occurred: ": "حدث خطأ: ",
          "Enter service type": "ادخل نوع الخدمة",
        
        },
      },
    },
  });

export default i18n;
