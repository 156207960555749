import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Image, Form, Button, Modal } from 'react-bootstrap';
import axiosInstance from '../api/axios';
import '../style-sheets/PublicPage.css';
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
import { LanguageContext } from "../contexts/LanguageContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ClientPage() {
  const [selectedLang, setSelectedLang] = useState(null);
  const language = useLanguage(); 
  const { t, i18n } = useTranslation();
  const [seller, setSeller] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [tax_registration_number, setTaxRegistrationNumber] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumber_alt, setPhoneNumber_alt] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [houseImage, setHouseImage] = useState(null);
  const [shipmentProvider, setShipmentProvider] = useState('');
  const [buttonWidth, setButtonWidth] = useState(''); 
  const [shipmentProviders, setShipmentProviders] = useState([]);
  const [cities, setCities] = useState([]);
  const [activeShipmentProvider, setActiveShipmentProvider] = useState(false); 
  const [otp, setOtp] = useState(''); 
  const [showOtpModal, setShowOtpModal] = useState(false); 
  const [isOtpVerified, setIsOtpVerified] = useState(false); 
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchSeller = async () => {
      try {
        const response = await axiosInstance.get('/client-page/');
        const { user, seller: sellerData } = response.data;
        setSeller({
          ...response.data,
          user: {
            ...user,
            image: `${axiosInstance.defaults.baseURL}${user.image}`,
            profile_cover: `${axiosInstance.defaults.baseURL}${user.profile_cover}`
          }
        });
        setStoreName(sellerData.store_name || '');
        setTaxRegistrationNumber(sellerData.tax_registration_number || '');
        setActiveShipmentProvider(sellerData.active_shipment_provider); 
        setBackgroundColor(sellerData.profile_color);
        console.log(backgroundColor);
      } catch (error) {
        console.error('Error fetching seller data:', error);
      }
    };

    const fetchShipmentProviders = async () => {
      try {
        const response = await axiosInstance.get('/shipment-providers/');
        setShipmentProviders(response.data);
      } catch (error) {
        console.error('Error fetching shipment providers:', error);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await axiosInstance.get('/areas/');
        setCities(response.data.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchSeller();
    fetchShipmentProviders();
    fetchCities();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      const inputWidth = inputRef.current.offsetWidth;
      setButtonWidth(`${inputWidth * 0.4}px`);
    }
  }, [inputRef.current]);

  const handleSendOtp = async () => {
    try {
      const response = await axiosInstance.post('/send-otp/', { phone: phoneNumber });
      if (response.status === 200) {
        setShowOtpModal(true);
        toast.success(t('otp.sentMessage'));
      } else {
        toast.error(t('otp.errorSending'));
      }
    } catch (error) {
      toast.error(t('otp.errorSending'));
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axiosInstance.post('/verify-otp/', { code: otp, phone: phoneNumber });
      if (response.status === 200) {
        toast.success(t('otp.verifiedMessage'));
        setShowOtpModal(false);
        setIsOtpVerified(true);
      } else {
        toast.error(t('otp.invalidOtp'));
      }
    } catch (error) {
      toast.error(t('otp.verificationFailed'));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const area = cities.find(area => area.id === parseInt(city));
    const areaName = area ? area.name : '';
    
    try {
      const formData = new FormData();
      formData.append('Client_Name', userName);
      formData.append('Client_Phone', phoneNumber);
      formData.append('Client_Phone_alt', phoneNumber_alt);
      formData.append('Client_Area', city);
      formData.append('Client_Area_name', areaName);
      formData.append('Client_Address', address);
      if (houseImage) {
        formData.append('house_image', houseImage || null);
      }
      formData.append('shipment_provider', shipmentProvider);

      const response = await axiosInstance.post('/client-records/', formData);

      if (response.status >= 200 && response.status < 300) {
        toast.success(t('client.successMessage'));
      } else {
        toast.error(t('client.errorMessage'));
      }
    } catch (error) {
      toast.error(t('client.errorMessage'));
    }
  };

  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    setHouseImage(imageFile);
  };

  const handleLang = async (e) => {
    setSelectedLang(e);
    await i18n.changeLanguage(e);
    localStorage.setItem("lang", e);
    window.location.reload(false);
  };

  const languageChecker = (englishCallback, arabicCallback) =>
    language.includes("en") ? englishCallback : arabicCallback;

  return (
    <div className='profile-wrapper' style={{ minHeight: '100vh', backgroundColor: `${backgroundColor}` }} dir={languageChecker("ltr", "rtl")}>
      <ToastContainer />
      <LanguageContext.Provider value={{ language }}>
        <Container fluid className="profile-container">
          <Row className="cover-picture" style={{ maxHeight: '300px', overflow: 'hidden' }}>
            <Image src={seller?.user?.profile_cover || ''} fluid alt={t("profile.coverPicture")} className="object-fit-cover" />
          </Row>

          <Row className="profile-picture-row justify-content-center">
            <Col xs={6} md={4} className="d-flex justify-content-center">
              <div className="profile-picture-container">
                <Image src={seller?.user?.image || ''} roundedCircle fluid alt={t("profile.profilePicture")} className="profile-picture" />
              </div>
            </Col>
          </Row>

          <Row className="mt-5 justify-content-center align-items-center text-center">
            <Col xs={10} md={6}>
              <p>{t("profile.welcome")} {storeName}</p>
              <p>{t("profile.taxNumber")}: {tax_registration_number}</p>
            </Col>
          </Row>
          <div className="language-switcher">
            <Button
              variant="outline-primary"
              onClick={() => {
                const newLang = languageChecker("ar", "en");
                i18n.changeLanguage(newLang);
              }}
            >
              {languageChecker("ع", "EN")}
            </Button>
          </div>

          <Row className="mt-3 justify-content-center align-items-center">
            <Col xs={10} md={6} className="text-center">
              <Form onSubmit={(e) => { e.preventDefault(); handleSendOtp(); }}>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="userName" className='mb-4'>
                      <Form.Control
                        ref={inputRef}
                        type="text"
                        placeholder={t("profile.namePlaceholder")}
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="phoneNumber" className='mb-4'>
                      <Form.Control
                        type="text"
                        placeholder={t("profile.phonePlaceholder")}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="phoneNumber_alt" className='mb-4'>
                      <Form.Control
                        type="text"
                        placeholder={t("profile.altPhonePlaceholder")}
                        value={phoneNumber_alt}
                        onChange={(e) => setPhoneNumber_alt(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="houseImage" className='mb-4'>
                      <Form.Control type="file" onChange={handleImageUpload} />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="city" className='mb-4'>
                      <Form.Control
                        as="select"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      >
                        <option value="">{t("profile.selectCity")}</option>
                        {cities.map((city, index) => (
                          <option key={index} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="address" className='mb-4'>
                      <Form.Control
                        type="text"
                        placeholder={t("profile.addressPlaceholder")}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Form.Group>
                    {activeShipmentProvider && (
                      <Form.Group controlId="shipmentProvider" className='mb-4'>
                        {/* <Form.Label>{t("profile.shipmentProvider")}</Form.Label> */}
                        <Form.Control
                          as="select"
                          value={shipmentProvider}
                          onChange={(e) => setShipmentProvider(e.target.value)}
                        >
                          <option value="">{t("profile.selectShipmentProvider")}</option>
                          {shipmentProviders.map((provider) => (
                            <option key={provider.shipment_provider_id} value={provider.shipment_provider_id}>
                              {provider.shipment_provider_name} - {provider.price}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    )}                    <Button type="submit" style={{ width: buttonWidth }}>
                      {t("otp.verify")}
                    </Button>
                  </Col>
                </Row>
              </Form>

              {isOtpVerified && (
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                  style={{ marginTop: '20px' }}
                >
                  {t("profile.submit")}
                </Button>
              )}
            </Col>
          </Row>
        </Container>

        <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("otp.enterOtp")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="otp">
                <Form.Control
                  type="text"
                  placeholder={t("otp.enterOtp")}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowOtpModal(false)}>
              {t("otp.cancel")}
            </Button>
            <Button variant="primary" onClick={handleVerifyOtp}>
              {t("otp.verify")}
            </Button>
          </Modal.Footer>
        </Modal>
      </LanguageContext.Provider>
    </div>
  );
}

export default ClientPage;
