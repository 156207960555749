import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, Spinner, Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PackageCard from './PackageCard';
import axiosInstance from '../../api/axios';
import ErrorHandler from '../error-handler/ErrorHandler';
import useLanguage from '../../hooks/useLanguage';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux
import { ArrowLeftOutlined } from '@ant-design/icons'; // Import Ant Design icon

const PackageList = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);
  const language = useLanguage(); 
  const { t, i18n } = useTranslation();
  const { failure } = useParams(); // Get the failure parameter from the URL
  const isAuth = useSelector((state) => state.auth.isAuth); // Use useSelector to get the isAuth state
  const history = useHistory(); // Use useHistory to programmatically navigate

  useEffect(() => {
    if (failure) {
      toast.error(t('paymentFailed'), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  }, [failure, t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get('packages/');
        if (res.data) {
          setPackages(res.data);
          setLoading(false);
        }
      } catch (error) {
        setError(t('errorFetchingPackages'));
        setLoading(false);
      }
    };

    fetchData();
  }, [t]);

  const handleLang = async (e) => {
    console.log(e);
    setSelectedLang(e);
    await i18n.changeLanguage(e);
    localStorage.setItem("lang", e);
    window.location.reload(false);
  };

  const languageChecker = (englishCallback, arabicCallback) =>
    language.includes("en") ? englishCallback : arabicCallback;

  console.log({ directionLOG: languageChecker("ltr", "rtl"), language });

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  // Sorting packages by ID
  packages.sort((a, b) => a.id - b.id);

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center vh-100"
      style={{ backgroundColor: `var(--primaryColor)`, direction: languageChecker('ltr', 'rtl') }}
    >
      {isAuth && (
        <Button
          variant="outline-light"
          className="mb-3"
          onClick={() => history.push('/profile')}
          style={{ position: 'absolute', top: '20px', left: '20px' }} // Adjust position as needed
        >
          <ArrowLeftOutlined />
        </Button>
      )}
      <h1 className="text-center mb-4" style={{ color: 'white', fontWeight: 'bold' }}>
        {t('directPackages')}
      </h1>
      <ToastContainer />
      <div className="language-switcher">
        <Button
          variant="outline-light"
          onClick={() => {
            const newLang = languageChecker("ar", "en");
            i18n.changeLanguage(newLang);
          }}
        >
          {languageChecker("ع", "EN")}
        </Button>
      </div>
      <Row>
        {packages.map((pkg) => (
          <Col key={pkg.id} xs={12} sm={6} md={4}>
            <PackageCard {...pkg} language={language} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ErrorHandler(PackageList, axiosInstance);
