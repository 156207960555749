import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Alert, Button, Modal, Form } from 'react-bootstrap';
import ErrorHandler from './error-handler/ErrorHandler';
import axiosInstance from '../api/axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import '../style-sheets/profile.css'; 
import TablePro from './TablePro/TablePro';
import { ClientsColumns } from './tableColumns/Column'; // Updated import
import { useHistory } from 'react-router-dom'; 


function ClientsTable({ merchantPackage }) {
  const { t } = useTranslation(); // Initialize useTranslation hook
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [ticketInfo, setTicketInfo] = useState({ quantity: '', height: '', weight: '', special_label: '', product: '', return_address: '' });
  const [shipmentProviders, setShipmentProviders] = useState([]);
  const [products, setProducts] = useState([]);
  const [hide , setHide] = useState(false);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axiosInstance.get('client-records/');
        setClients(response.data.results);
      } catch (error) {
        console.error('Error fetching clients data:', error);
        setError('Failed to fetch clients data.');
      }
      setLoading(false);
    };
    fetchClients();

    // Fetch shipment providers
    axiosInstance.get('/shipment-providers/')
      .then(response => {
        setShipmentProviders(response.data);
      })
      .catch(error => {
        console.error('Error fetching shipment providers:', error);
      });

    // Fetch products
    axiosInstance.get('/products/')
      .then(response => {
        setProducts(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });

      if (merchantPackage && merchantPackage.remaining_orders === 0) {
        setHide(true);
      }
  }, []);

  
  const openModal = (record) => {
    setSelectedClient(record);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedClient(null);
    setTicketInfo({ quantity: '', height: '', weight: '', special_label: '', product: '', return_address: '' });
  };

  const handleProviderChange = (clientId, providerId) => {
    setClients(clients.map(client => {
      if (client.id === clientId) {
        return { ...client, selectedShipmentProvider: providerId };
      }
      return client;
    }));
  };

  const handleOrderSubmit = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmOrder = () => {
    const orderData = {
      address: { id: selectedClient.Client_Area },
      client: {
        client_addresses: [
          { address: selectedClient.Client_Address, state: selectedClient.Client_Area, zip_code: '12345', area: selectedClient.Client_Area }
        ],
        phone: selectedClient.Client_Phone,
        phone_alt: selectedClient.Client_Phone_alt,
        name: selectedClient.Client_Name,
        seller: selectedClient.Client_Area
      },
      product: {
        id: ticketInfo.product,
      },
      shipment_provider_id: selectedClient.selectedShipmentProvider || selectedClient.shipment_provider,
      tickets: [
        {
          type: true,
          quantity: ticketInfo.quantity,
          height: ticketInfo.height,
          weight: ticketInfo.weight,
          special_label: ticketInfo.special_label,
          product: ticketInfo.product,
          return_address: selectedClient.Client_Area
        }
      ]
    };

    axiosInstance.post('/create-order/', orderData)
      .then(response => {
        console.log('Order created successfully:', response.data);

        // Update the client's status to true
        axiosInstance.put(`/client-records/${selectedClient.id}/`, { status: true })
          .then(() => {
            setClients(clients.map(client => client.id === selectedClient.id ? { ...client, status: true } : client));
          })
          .catch(error => {
            console.error('Error updating client status:', error);
          });

        closeModal(); // Close modals on success
        setShowConfirmationModal(false);
      })
      .catch(error => {
        console.error('Error creating order:', error);
      });
  };

  const handleCancelOrder = () => {
    setShowConfirmationModal(false);
    closeModal();
  };

  // if (merchantPackage && merchantPackage.remaining_orders === 0) {
  //   setHide(true);
  //   return (
  //     <Alert variant="warning" className="mt-3">
  //       {t('renewmsg')}
  //       <Button variant="primary" onClick={() => history.push('/packages')} className="mt-3">
  //         {t('renew')}
  //       </Button>
  //     </Alert>
  //   );
  // }
  const columns = ClientsColumns(openModal, shipmentProviders, handleProviderChange ,hide);

  
  if (loading) {
    return (
      <div className="loading-spinner">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="mt-3">
        {error}
      </Alert>
    );
  }

  return (
    <div className="profile-wrapper" style={{ minHeight: '100vh'}}>
      <Container fluid className="profile-container">
        <Row className="mt-5 justify-content-center align-items-center">
          <Col xs={10}>
            <TablePro
              dataSource={clients}
              columns={columns}
            />
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('clientsTable.createOrder')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedClient && (
            <Form>
              <Form.Group controlId="product">
                <Form.Label>{t('clientsTable.product')}</Form.Label>
                <Form.Control
                  as="select"
                  value={ticketInfo.product}
                  onChange={(e) => setTicketInfo({ ...ticketInfo, product: e.target.value })}
                >
                  <option value="">{t('clientsTable.product')}</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name} - {product.price}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="quantity">
                <Form.Label>{t('clientsTable.quantity')}</Form.Label>
                <Form.Control type="number" value={ticketInfo.quantity} onChange={(e) => setTicketInfo({ ...ticketInfo, quantity: e.target.value })} />
              </Form.Group>
              <Form.Group controlId="height">
                <Form.Label>{t('clientsTable.height')}</Form.Label>
                <Form.Control type="number" value={ticketInfo.height} onChange={(e) => setTicketInfo({ ...ticketInfo, height: e.target.value })} />
              </Form.Group>
              <Form.Group controlId="weight">
                <Form.Label>{t('clientsTable.weight')}</Form.Label>
                <Form.Control type="number" value={ticketInfo.weight} onChange={(e) => setTicketInfo({ ...ticketInfo, weight: e.target.value })} />
              </Form.Group>
              <Form.Group controlId="specialLabel">
                <Form.Label>{t('clientsTable.specialLabel')}</Form.Label>
                    <Form.Control 
                      as="select" 
                      value={ticketInfo.special_label} 
                      onChange={(e) => setTicketInfo({ ...ticketInfo, special_label: e.target.value })}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                  </Form.Control>
                </Form.Group>           
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {t('clientsTable.cancel')}
          </Button>
          <Button variant="primary" onClick={handleOrderSubmit}>
            {t('clientsTable.submit')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmationModal} onHide={handleCancelOrder}>
        <Modal.Header closeButton>
          <Modal.Title>{t('clientsTable.confirmOrderMessage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('clientsTable.confirmOrderMessage', { clientName: selectedClient && selectedClient.Client_Name })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelOrder}>
            {t('clientsTable.cancel')}
          </Button>
          <Button variant="primary" onClick={handleConfirmOrder}>
            {t('clientsTable.submit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ErrorHandler(ClientsTable, axiosInstance);
