import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./store";
import i18n from "./resourses/i18n";
import "./resourses/Localization";
import "./i18next";
import 'antd/dist/antd.css';

i18n.on("initialized", (options) => {
  console.log({ options: options.lang });

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
