import React from 'react';
import { Badge } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function MerchantPackageBadge({ packageName, packageNameEn, remainingOrders, subscriptionDate }) {
  const { i18n, t } = useTranslation();
  const language = i18n.language;
  
  const formattedDate = moment(subscriptionDate).format('DD/MM/YY');
  const displayedPackageName = language === 'ar' ? packageName : packageNameEn;

  const orderText = remainingOrders > 0
    ? t('remainingOrders', { count: remainingOrders })
    : t('remainingOrders', { count: remainingOrders });

  return (
    <Badge 
    bg='Light'
      className="merchant-package-badge" 
      dir={language === 'ar' ? 'rtl' : 'ltr'}
    >
      <div className="badge-header ">{displayedPackageName}</div>
      <div className="badge-details">
        {orderText}
      </div>
      <div className="badge-date">
        {t('subscriptionDate', { date: formattedDate })}
      </div>
    </Badge>
  );
}

export default MerchantPackageBadge;
