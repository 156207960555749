import React, { useEffect, useMemo } from "react";
import styles from "./TablePro.module.css";
import { Empty, Form, Input, Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIcon } from "../../images/search.svg";

export default function TablePro({
    selection,
    selectedRows,
    setSelectedRows,
    setSelectedRecords,
    rowKey,
    onSearch,
    emptyPlaceholder,
    onRowClick,
    searchPlaceholder,
    title,
    headerExtra,
    disableHeaders,
    unselectableRowsCondition,
    ...props
}) {
    const { t } = useTranslation();
    console.log({ selectedRows });
    const onSelect = (record, selected) => {
        if (selected) {
            if (selection === "radio") {
                setSelectedRows([record.id]);
                setSelectedRecords?.([record]);
            } else {
                setSelectedRows((prev) => [...prev, record.id]);
                setSelectedRecords?.((prev) => [...prev, record]);
            }
        } else {
            setSelectedRows((prev) => {
                const index = prev.indexOf(record.id);
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            });
            setSelectedRecords?.((prev) => {
                const index = prev.indexOf(record.id);
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            });
        }
    };

    const onSelectAll = (selected) => {
        const all = unselectableRowsCondition
            ? props.dataSource
                  .filter((record) => !unselectableRowsCondition(record))
                  .map((it) => it.id)
            : props.dataSource.map((it) => it.id);
        if (selected) {
            setSelectedRows(all);
            setSelectedRecords?.(props.dataSource);
        } else {
            setSelectedRows([]);
            setSelectedRecords?.([]);
        }
    };
    const columns = useMemo(() => {
        const columns = props.columns ?? [];
        if (columns[0] && onRowClick) {
            columns[0].onCell = (record, index) => ({
                onClick: (e) => onRowClick?.(index, record, e),
            });
            columns[0].className = "clickable";
        }
        return columns;
    }, [props.columns, onRowClick]);

    return (
        <div
            className={`tableContainer ${
                disableHeaders ? "disableHeaders" : ""
            }`}
        >
            {(onSearch || headerExtra || title) && (
                <div className={"p-3 row-centered-horizontal"}>
                    {title && (
                        <div style={{ marginInlineEnd: "auto" }}>{title}</div>
                    )}
                    {onSearch && (
                        <Form
                            style={{ width: "50%", paddingInlineEnd: "8px" }}
                            onFinish={({ search }) => {
                                onSearch(search);
                            }}
                        >
                            <Form.Item
                                name="search"
                                style={{ marginBottom: "0 !important" }}
                            >
                                <Input
                                    placeholder={
                                        searchPlaceholder ?? t("search")
                                    }
                                    prefix={
                                        <button
                                            style={{
                                                marginTop: "-3px",
                                                marginInlineEnd: "8px",
                                            }}
                                            type={"submit"}
                                            className={"action"}
                                        >
                                            <SearchIcon />
                                        </button>
                                    }
                                />
                            </Form.Item>
                        </Form>
                    )}
                    <div style={{ marginBottom: "8px" }}>{headerExtra}</div>
                </div>
            )}
            <Table
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: (e) => {
                //             console.log({ record });
                //             onRowClick?.(rowIndex, record, e);
                //         },
                //     };
                // }}
                preserveSelectedRowKeys
                {...props}
                columns={columns}
                locale={{
                    emptyText: !props.loading && (
                        <div className="table-empty-placeholder">
                            {emptyPlaceholder ?? (
                                <div
                                    style={{
                                        width: "fit-content",
                                    }}
                                >
                                    <Empty />
                                    <div>{t("empty")}</div>
                                </div>
                            )}
                        </div>
                    ),
                }}
                rowSelection={
                    selection
                        ? {
                              selectedRowKeys: selectedRows,
                              type:
                                  selection === "radio" ? "radio" : "checkbox",
                              fixed: true,
                              getCheckboxProps: (record) => ({
                                  disabled:
                                      unselectableRowsCondition?.(record) ??
                                      false,
                              }),
                              onSelect,
                              onSelectAll,
                          }
                        : undefined
                }
                rowKey={rowKey ?? ((record) => record.id)}
                loading={{
                    spinning: props.loading ?? false,
                    indicator: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Spin />
                        </div>
                    ),
                }}
            />
        </div>
    );
}
