import { LOGOUT, LOGIN, ACCESS_TOKEN } from "./actionTypes";
import axios from "axios";
import axiosInstance , { BASE_URL } from "../../api/axios";
import Cookies from "js-cookie";

export const userLogin = (userData, rememberMe) => async (dispatch) => {
  try {
      const response = await axiosInstance.post(`/auth/jwt/create/`, userData);
      const responseData = response.data;

      // If login is successful
      if (responseData) {
          const accessToken = responseData.user.access;
          const refreshToken = responseData.user.refresh;
          const isSubscribed = responseData.user.subscribed;
          console.log(isSubscribed);
          
          // Store tokens and rememberMe in local storage
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("rememberMe", rememberMe);

          // Store access token in cookie
          Cookies.set("accessToken", accessToken);

          // Dispatch actions to update Redux state
          dispatch({ type: ACCESS_TOKEN, payload: accessToken });
          dispatch({ type: LOGIN, payload: responseData });

          // Return isSubscribed to handle navigation in the component
          return isSubscribed;
      }
  } catch (error) {
      console.error("Login error:", error);
      return false;
  }
};

export const logOut = () => async (dispatch) => {
  // Clear tokens and rememberMe from local storage
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("rememberMe");

  // Remove access token from cookie
  Cookies.remove("accessToken");

  // Dispatch logout action
  dispatch({ type: LOGOUT });
};
